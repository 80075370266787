.login {
  display: flex;
  background-color: var(--bg);
  min-height: 100vh;
  place-content: center;
  place-items: center;
}

.login__container {
  background-color: var(--black);
  color: var(--white);
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 20px;
  padding: 60px 30px;
  place-content: center;
}

.login__logo {
  display: flex;
  place-content: center;
}

.login__content {
  margin: 0 25px;
  text-align: center;
  place-content: center;
}

.login__content > h2 {
  margin: 10px 0px;
}

.login__content > p {
  font-size: 14px;
}

.login__content > hr {
  margin-top: 20px;
}

.firebaseui-idp-button {
  max-width: none !important;
  min-height: 50px !important;
}

.firebaseui-card-content {
  padding: 0px 0px !important;
}

@media (max-width: 480px) {
  .login__container {
    width: 100%;
    padding: 0 0;
  }
}